<template>
  <div class="profile-section-title">
    <span class="profile-section-title__text">
      {{ text }}
    </span>
    <div
      class="profile-section-title__edit-button"
      @click="$emit('edit')"
    >
      <span class="mdi mdi-cog-outline" />
      <span>
        {{ $t('sections.listing-detail.editButton') }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListingDetailProfileSectionTitle',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    color: $grey;
    font-size: 28px;
    line-height: 44px;
  }

  &__edit-button {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $grey;
    font-weight: 600;
    cursor: pointer;

    .mdi {
      font-size: 24px;
      height: 24px;
      line-height: 1;
      aspect-ratio: 1;
    }
  }
}

@media only screen and (max-width: 770px) {
  .profile-section-title {
    &__text {
      font-size: 24px;
      line-height: 36px;
    }

    &__edit-button {
      font-size: 14px;

      .mdi {
        font-size: 20px;
        height: 20px;
      }
    }
  }
}
</style>
