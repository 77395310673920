<template>
  <InternalLayout>
    <template slot="body">
      <div class="listing-detail-screen">
        <div class="d-flex margin-container">
          <div
            class="back-button"
            @click="
              $router.push({
                name: 'dashboard-listings',
              })
            "
          >
            <span class="mdi mdi-chevron-left" />
            <span>
              {{ $t('sections.listing-detail.backButton') }}
            </span>
          </div>
        </div>
        <div class="listing-detail-screen__inner">
          <div class="d-flex flex-column margin-container">
            <span class="listing-title">
              {{ property.title }}
            </span>
            <Tabs
              name="listing-section-tabs"
              :tabs="$t('sections.listing-detail.tabs')"
              @change="handleTabsChange"
            />
          </div>
          <div
            class="profile-section"
            v-if="section === 0"
          >
            <ListingDetailProfileMainSection
              :_id="property._id"
              :photos="property.photos"
              :location="`${property.district}, ${property.city}`"
              :title="property.title"
              :is-rent="property.listingType === 'RENT'"
              :price="property.value"
              @edit="handleEdit"
            />
            <div class="divider margin-container" />
            <ListingDetailProfileDetailsSection
              :characteristics="{
                energeticClass: property.energeticClass,
                typology: property.typology,
                floor: property.floor,
                area: property.area,
                beds: property.typology
                  ? Number(property.typology.slice(1))
                  : '',
                bathrooms: property.wcs,
                garageSpots: property.garageSpaces,
                // elevators: property.elevators,
              }"
              :description="property.description"
              @edit="handleEdit"
            />
            <div class="divider margin-container" />
            <ListingDetailProfileCharacteristicsSection
              :characteristics="property.characteristics"
              @edit="handleEdit"
            />
            <div class="divider margin-container" />
            <ListingDetailProfileMapSection
              v-if="
                property.coordinates &&
                  property.coordinates.latitude != null &&
                  property.coordinates.longitude != null
              "
              :coordinates="[
                property.coordinates.latitude,
                property.coordinates.longitude,
              ]"
              @edit="handleEdit"
            />
          </div>
          <ListingDetailDocumentsSection
            :fields="documents"
            v-if="section === 1"
            @upload="handleDocumentUpload"
            @download="handleDocumentDownload"
          />
          <div class="divider margin-container" />
        </div>
        <div class="d-flex margin-container">
          <div
            class="back-button"
            @click="$emit('back')"
          >
            <span class="mdi mdi-chevron-left" />
            <span>
              {{ $t('sections.listing-detail.backButton') }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </InternalLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { Tabs } from 'ff-components-lib';

import InternalLayout from '@/components/layout/internal/InternalLayout';
import ListingDetailProfileMainSection from '@/components/sections/internal/listing-detail/ListingDetailProfileMainSection';
import ListingDetailProfileMapSection from '@/components/sections/internal/listing-detail/ListingDetailProfileMapSection';
import ListingDetailProfileDetailsSection from '@/components/sections/internal/listing-detail/ListingDetailProfileDetailsSection';
import ListingDetailProfileCharacteristicsSection from '@/components/sections/internal/listing-detail/ListingDetailProfileCharacteristicsSection';
import ListingDetailDocumentsSection from '@/components/sections/internal/listing-detail/ListingDetailDocumentsSection';

export default {
  name: 'ListingDetailScreen',
  components: {
    InternalLayout,
    Tabs,
    ListingDetailProfileMainSection,
    ListingDetailProfileMapSection,
    ListingDetailProfileDetailsSection,
    ListingDetailProfileCharacteristicsSection,
    ListingDetailDocumentsSection,
  },
  data() {
    return {
      section: 0,
      property: {
        photos: [],
        type: undefined,
        district: undefined,
        city: undefined,
        title: undefined,
        description: undefined,
        area: undefined,
        typology: undefined,
        wcs: undefined,
        garageSpaces: undefined,
        // elevators: undefined,
        value: undefined,
        ownerName: undefined,
        energeticClass: undefined,
        floor: undefined,
        characteristics: [],
        coordinates: {
          latitude: undefined,
          longitude: undefined,
        },
      },
      documents: {
        buildingPassbook: undefined,
        energeticCertificate: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  async created() {
    this.$store.dispatch('storeLoading', true);
    this.property = await this.$store.dispatch(
      'getProperty',
      this.$route.params.id,
    );
    this.$store.dispatch('storeLoading', false);

    if (this.property.buildingPassbook) {
      const buildingPassbookResp = await this.$store.dispatch(
        'getPropertyDocument',
        {
          id: this.$route.params.id,
          fileName: `buildingPassbook/${this.property.buildingPassbook}`,
          type: 'documents',
        },
      );

      const buildingPassbookBlob = new Blob([buildingPassbookResp.file], {
        type: buildingPassbookResp.fileType,
      });
      this.documents.buildingPassbook = new File(
        [buildingPassbookBlob],
        this.property.buildingPassbook,
        { type: buildingPassbookResp.fileType },
      );
    }
    if (this.property.energeticCertificate) {
      const energeticCertificateResp = await this.$store.dispatch(
        'getPropertyDocument',
        {
          id: this.$route.params.id,
          fileName: `energeticCertificate/${this.property.energeticCertificate}`,
          type: 'documents',
        },
      );

      const energeticCertificateBlob = new Blob(
        [energeticCertificateResp.file],
        {
          type: energeticCertificateResp.fileType,
        },
      );
      this.documents.energeticCertificate = new File(
        [energeticCertificateBlob],
        this.property.energeticCertificate,
        { type: energeticCertificateResp.fileType },
      );
    }
  },
  methods: {
    handleTabsChange(tab) {
      this.section = tab;
    },
    handleInput(field, value) {
      this[field] = value;
    },
    handleEdit() {
      this.$router.push({
        name: 'rent-process-edit',
        params: {
          id: this.property._id,
        },
      });
    },
    async handleDocumentDownload(docType) {
      const file = this.documents[docType];

      if (!file) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      } else {
        const fileURL = window.URL.createObjectURL(new Blob([file]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.name);
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    },
    async handleDocumentUpload(file) {
      const { field, value } = file;
      this.documents[field] = value;
      await this.$store.dispatch('uploadPropertyDocument', {
        id: this.$route.params.id,
        type: field,
        document: value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-section {
  display: flex;
  flex-direction: column;
  gap: 74px;
}

.listing-detail-screen {
  display: flex;
  flex-direction: column;
  gap: 48px;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 74px;

    .listing-title {
      color: $primary;
      font-size: 32px;
      letter-spacing: -0.64px;
      margin-bottom: 24px;
      text-align: center;
    }

    .divider {
      background-color: rgba($grey, 0.2);
      height: 1px;
    }
  }
}

.back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  color: $primary;
  font-weight: 600;
  line-height: 28px;
  cursor: pointer;

  .mdi {
    font-size: 20px;
    height: 20px;
    line-height: 1;
    aspect-ratio: 1;
  }
}

@media only screen and (max-width: 770px) {
  .profile-section {
    gap: 50px;
  }

  .listing-detail-screen {
    gap: 20px;

    &__inner {
      gap: 50px;

      .listing-title {
        font-size: 28px;
      }
    }
  }

  .back-button {
    gap: 10px;
  }
}
</style>
