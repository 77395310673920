<template>
  <div class="documents-section margin-container">
    <VerticalTitleInput
      type="document"
      :title="$t('sections.listing-detail.documents.buildingPassbook')"
      :value="buildingPassbook"
      download
      @input="(e) => handleInput('buildingPassbook', e)"
      @delete="handleInput('buildingPassbook', null)"
      @download="$emit('download', 'buildingPassbook')"
    />
    <VerticalTitleInput
      type="document"
      :title="$t('sections.listing-detail.documents.energeticCertificate')"
      :value="energeticCertificate"
      download
      @input="(e) => handleInput('energeticCertificate', e)"
      @delete="handleInput('energeticCertificate', null)"
      @download="$emit('download', 'energeticCertificate')"
    />
  </div>
</template>
<script>
import { VerticalTitleInput } from 'ff-components-lib';

export default {
  name: 'ListingDetailDocumentsSection',
  components: {
    VerticalTitleInput,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      buildingPassbook: undefined,
      energeticCertificate: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      if (value) {
        this.$emit('upload', { field, value });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.documents-section {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
</style>
