var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InternalLayout',[_c('template',{slot:"body"},[_c('div',{staticClass:"listing-detail-screen"},[_c('div',{staticClass:"d-flex margin-container"},[_c('div',{staticClass:"back-button",on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-listings',
            })}}},[_c('span',{staticClass:"mdi mdi-chevron-left"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('sections.listing-detail.backButton'))+" ")])])]),_c('div',{staticClass:"listing-detail-screen__inner"},[_c('div',{staticClass:"d-flex flex-column margin-container"},[_c('span',{staticClass:"listing-title"},[_vm._v(" "+_vm._s(_vm.property.title)+" ")]),_c('Tabs',{attrs:{"name":"listing-section-tabs","tabs":_vm.$t('sections.listing-detail.tabs')},on:{"change":_vm.handleTabsChange}})],1),(_vm.section === 0)?_c('div',{staticClass:"profile-section"},[_c('ListingDetailProfileMainSection',{attrs:{"_id":_vm.property._id,"photos":_vm.property.photos,"location":((_vm.property.district) + ", " + (_vm.property.city)),"title":_vm.property.title,"is-rent":_vm.property.listingType === 'RENT',"price":_vm.property.value},on:{"edit":_vm.handleEdit}}),_c('div',{staticClass:"divider margin-container"}),_c('ListingDetailProfileDetailsSection',{attrs:{"characteristics":{
              energeticClass: _vm.property.energeticClass,
              typology: _vm.property.typology,
              floor: _vm.property.floor,
              area: _vm.property.area,
              beds: _vm.property.typology
                ? Number(_vm.property.typology.slice(1))
                : '',
              bathrooms: _vm.property.wcs,
              garageSpots: _vm.property.garageSpaces,
              // elevators: property.elevators,
            },"description":_vm.property.description},on:{"edit":_vm.handleEdit}}),_c('div',{staticClass:"divider margin-container"}),_c('ListingDetailProfileCharacteristicsSection',{attrs:{"characteristics":_vm.property.characteristics},on:{"edit":_vm.handleEdit}}),_c('div',{staticClass:"divider margin-container"}),(
              _vm.property.coordinates &&
                _vm.property.coordinates.latitude != null &&
                _vm.property.coordinates.longitude != null
            )?_c('ListingDetailProfileMapSection',{attrs:{"coordinates":[
              _vm.property.coordinates.latitude,
              _vm.property.coordinates.longitude ]},on:{"edit":_vm.handleEdit}}):_vm._e()],1):_vm._e(),(_vm.section === 1)?_c('ListingDetailDocumentsSection',{attrs:{"fields":_vm.documents},on:{"upload":_vm.handleDocumentUpload,"download":_vm.handleDocumentDownload}}):_vm._e(),_c('div',{staticClass:"divider margin-container"})],1),_c('div',{staticClass:"d-flex margin-container"},[_c('div',{staticClass:"back-button",on:{"click":function($event){return _vm.$emit('back')}}},[_c('span',{staticClass:"mdi mdi-chevron-left"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('sections.listing-detail.backButton'))+" ")])])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }