<template>
  <div class="details-section margin-container">
    <ListingDetailProfileSectionTitle
      :text="$t('sections.listing-detail.profile.details')"
      @edit="$emit('edit')"
    />
    <div class="details">
      <div class="main-characteristics">
        <div class="main-group">
          <img
            :src="require(`@/assets/svgs/energeticClasses/${characteristics.energeticClass}.svg`)"
            class="energetic-class"
            v-if="characteristics.energeticClass"
          >
          <span>
            {{ characteristics.typology }}
          </span>
          <span>
            {{ floorText }}
          </span>
          <span>
            {{ `${characteristics.area}m²` }}
          </span>
        </div>
        <div class="icon-groups">
          <div class="icon-group">
            <span class="mdi mdi-bed-outline" />
            <span>
              {{ characteristics.beds }}
            </span>
          </div>
          <div class="icon-group">
            <span class="mdi mdi-shower" />
            <span>
              {{ characteristics.bathrooms }}
            </span>
          </div>
          <div class="icon-group">
            <span class="mdi mdi-car-outline" />
            <span>
              {{ characteristics.garageSpots }}
            </span>
          </div>
          <!-- <div class="icon-group">
            <span class="mdi mdi-elevator-passenger-outline" />
            <span>
              {{ characteristics.elevators }}
            </span>
          </div> -->
        </div>
      </div>
      <span
        class="description"
        v-html="description"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { numberToDotNotationString, validateNumber } from '@/utils';
import ListingDetailProfileSectionTitle from '@/components/ui/ListingDetailProfileSectionTitle';

export default {
  name: 'ListingDetailProfileDetailsSection',
  components: {
    ListingDetailProfileSectionTitle,
  },
  props: {
    characteristics: {
      type: Object,
      default: () => {},
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
    floorText() {
      if (
        this.characteristics.floor != null
        && validateNumber(this.characteristics.floor)
      ) {
        return `${this.characteristics.floor}º ${this.$t(
          'sections.listing-detail.profile.floor',
        )}`;
      }
      return this.characteristics.floor;
    },
  },
  methods: {
    numberToDotNotationString,
  },
};
</script>
<style lang="scss" scoped>
.details-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 4%;
  padding-right: 4%;

  .details {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .main-characteristics {
      display: flex;
      align-items: center;
      gap: 30px;
      color: $grey;
      font-weight: 600;

      .main-group {
        display: flex;
        align-items: center;
        gap: 12px;

        .energetic-class {
          width: 30px;
          height: 30px;
          line-height: 1;
        }
      }

      .icon-groups {
        display: flex;
        align-items: center;
        gap: 30px;

        .icon-group {
          display: flex;
          align-items: center;
          gap: 4px;

          & > .mdi {
            width: 26px;
            height: 26px;
            font-size: 26px;
            line-height: 1;
          }
        }
      }
    }

    .description {
      white-space: break-spaces;
    }
  }
}

@media only screen and (max-width: 770px) {
  .details-section {
    gap: 24px;
    padding-left: 3%;
    padding-right: 3%;

    .details {
      .main-characteristics {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        .main-group {
          gap: 10px;

          .energetic-class {
            width: 22px;
            height: 22px;
          }
        }

        .icon-groups {
          gap: 12px;

          .icon-group {
            gap: 4px;

            & > .mdi {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
