<template>
  <div class="main-section">
    <flickity
      class="photos-section"
      ref="flickity"
      :options="flickityOptions"
      v-if="photos && photos.length > 0"
    >
      <div
        class="carousel-photo"
        v-for="(photo, index) in photos"
        :key="`property-image-${index}`"
      >
        <img
          :src="
            `https://storage.googleapis.com/bk-firstfloor-properties/${_id}/photos/${photo}`
          "
        >
      </div>
    </flickity>
    <div
      class="photos-section"
      v-else
    >
      <img
        class="carousel-photo"
        :src="require(`@/assets/imgs/property_placeholder.jpg`)"
      >
    </div>
    <div class="main-section__bottom">
      <div class="d-flex justify-content-between">
        <span class="location">{{ location }}</span>
        <div
          class="edit-button"
          @click="$emit('edit')"
        >
          <span class="mdi mdi-cog-outline" />
          <span>
            {{ $t('sections.listing-detail.editButton') }}
          </span>
        </div>
      </div>
      <span class="title">{{ title }}</span>
      <span class="price">
        {{
          `${numberToDotNotationString(price)}€${
            isRent ? $t('sections.listing-detail.profile.eachMonth') : ''
          }`
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Flickity from 'vue-flickity';
import { numberToDotNotationString } from '@/utils';

export default {
  name: 'ListingDetailProfileMainSection',
  components: {
    Flickity,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    _id: {
      type: String,
      default: '',
    },
    photos: {
      type: Array,
      default: () => [],
    },
    location: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    isRent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        arrowShape: {
          x0: 10,
          x1: 45,
          y1: 45,
          x2: 50,
          y2: 45,
          x3: 15,
        },
        pageDots: true,
        initialIndex: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  methods: {
    numberToDotNotationString,
  },
};
</script>
<style lang="scss" scoped>
.main-section {
  display: flex;
  flex-direction: column;
  margin-left: 14%;
  margin-right: 14%;

  border-radius: 48px;
  background: white;
  box-shadow: 0px 4px 24px rgba($primary, 0.1);

  .photos-section {
    .carousel-photo {
      height: 670px;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 48px 48px 0 0;
      }
    }

    ::v-deep .flickity-page-dots {
      bottom: 25px;
    }

    ::v-deep .dot {
      background-color: $tertiary;
      border-radius: 4px;
      width: 32px;
      height: 4px;
      margin: 0 4px;
    }

    ::v-deep .flickity-button {
      background: unset;
      width: 110px;
      height: 110px;
      border-radius: unset;
      color: $tertiary;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 48px 114px;

    .location {
      color: $grey;
      font-size: 22px;
      line-height: 32px;
    }

    .edit-button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $grey;
      font-weight: 600;
      cursor: pointer;

      .mdi {
        font-size: 24px;
        height: 24px;
        line-height: 1;
        aspect-ratio: 1;
      }
    }

    .title {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.72px;
      margin-top: 8px;
      margin-bottom: 32px;
    }

    .price {
      font-size: 36px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 2000px) {
  .main-section {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 1750px) {
  .main-section {
    margin-left: 4%;
    margin-right: 4%;
  }
}

@media only screen and (max-width: 770px) {
  .main-section {
    margin-left: 3%;
    margin-right: 3%;

    .photos-section {
      .carousel-photo {
        height: 370px;
      }

      ::v-deep .flickity-page-dots {
        bottom: 12px;
      }

      ::v-deep .dot {
        width: 16px;
        height: 2px;
      }

      ::v-deep .flickity-button {
        width: 0;
        height: 0;
      }
    }

    &__bottom {
      padding: 30px 40px;

      .location {
        font-size: 18px;
        line-height: 32px;
      }

      .edit-button {
        font-size: 14px;

        .mdi {
          font-size: 20px;
          height: 20px;
        }
      }

      .title {
        font-size: 30px;
        margin-bottom: 26px;
      }

      .price {
        font-size: 28px;
      }
    }
  }
}
</style>
