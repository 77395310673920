<template>
  <div class="map-section margin-container">
    <ListingDetailProfileSectionTitle
      :text="$t('sections.listing-detail.profile.location')"
      @edit="$emit('edit')"
    />
    <l-map
      :zoom="15"
      :min-zoom="7"
      :max-zoom="18"
      :center="coordinates"
      :max-bounds="maxBounds"
      class="map"
      ref="map"
      v-if="coordinates"
    >
      <l-tile-layer
        :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
      />
      <l-marker :lat-lng="coordinates">
        <l-icon
          :icon-url="require('@/assets/imgs/map_marker.png')"
          :icon-size="[40, 40]"
          :icon-anchor="[20, 20]"
        />
      </l-marker>
    </l-map>
    <div class="distances-container">
      <div class="distance-detail">
        <span class="distance-detail__title">
          Hospitais
        </span>
        <span class="distance-detail__distance">
          2 km
        </span>
      </div>
      <div class="distance-detail">
        <span class="distance-detail__title">
          Hospitais
        </span>
        <span class="distance-detail__distance">
          2 km
        </span>
      </div>
      <div class="distance-detail">
        <span class="distance-detail__title">
          Hospitais
        </span>
        <span class="distance-detail__distance">
          2 km
        </span>
      </div>
      <div class="distance-detail">
        <span class="distance-detail__title">
          Hospitais
        </span>
        <span class="distance-detail__distance">
          2 km
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  LMap, LTileLayer, LMarker, LIcon,
} from 'vue2-leaflet';
import ListingDetailProfileSectionTitle from '@/components/ui/ListingDetailProfileSectionTitle';

export default {
  name: 'ListingDetailProfileMapSection',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    ListingDetailProfileSectionTitle,
  },
  props: {
    coordinates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapCenter: [47.41322, -1.199482],
      maxBounds: [
        [-85, -10000],
        [85, 10000],
      ],
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
    url() {
      // darkmode 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
      return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    },
  },
};
</script>
<style lang="scss" scoped>
.map-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 4%;
  padding-right: 4%;

  .map {
    width: 100%;
    height: 520px;
    z-index: 1;
  }

  .distances-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;

    .distance-detail {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: $grey;
      }

      &__distance {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .map-section {
    gap: 24px;
    padding-left: 3%;
    padding-right: 3%;

    .map {
      height: 300px;
    }

    .distances-container {
      justify-content: space-between;
      padding-left: 3%;
      padding-right: 3%;
      gap: 12px;

      .distance-detail {
        // width: 120px;

        &__title {
          font-size: 16px;
          line-height: 24px;
        }

        &__distance {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .map-section {
    .distances-container {
      .distance-detail {
        width: 120px;
      }
    }
  }
}
</style>
